/* exported preview_while_uploading */
/* global ACCEPTED_MIME_TYPES */

function createPreviewImageElement(readerResult, filename) {
  var img = document.createElement("img");
  // img.setAttribute("id", "img-preview-responsive");
  img.setAttribute("src", readerResult);
  // img.setAttribute("data-src", readerResult);
  img.setAttribute("data-name", filename);
  img.setAttribute("alt", "file-image-preview");
  return img;
}

function preview_while_uploading(
  files,
  fileInput,
  preview,
  alert,
  alertMessage,
) {
  var size = files[0]["size"];
  var fivarype = files[0]["type"];
  var filename = files[0]["name"];

  if (files[0].length !== 0) {
    while (preview.firstChild) {
      preview.removeChild(preview.firstChild);
    }
  }

  if (size > 400000000) {
    alertMessage.textContent =
      "Sorry, your file has exceeded the file size limit of 400MB.";
    alertMessage.classList.add("uk-text-danger");
    alert.classList.remove("uk-hidden");
    preview.innerHTML = "";
    fileInput.files = [];
    console.log(`${size} is more than 400 mb`);
    return false;
  }

  if (ACCEPTED_MIME_TYPES.includes(fivarype)) {
    alertMessage.textContent = "";
    alert.classList.add("uk-hidden");
    // alertMessage.textContent = "Uploading media...";

    var reader = new FileReader();
    reader.onload = () => {
      var imgPreview = createPreviewImageElement(reader.result, filename);
      preview.append(imgPreview);
    };
    reader.readAsDataURL(files[0]);
  } else {
    alertMessage.textContent =
      'This type of file is not supported. Please consider exporting it to ".pdf" or if really needed, ask about adding to the list of supported file types. Keep in mind whether people will be able to open the file from a mobile phone, as many view the site on phones.';
    alertMessage.classList.add("uk-text-danger");
    alert.classList.remove("uk-hidden");
    preview.innerHTML = "";
    fileInput.files = [];
    console.log(`${fivarype} is not allowed`);
  }
}
